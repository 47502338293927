import React, { useState, useEffect } from 'react';
import LoadingOverlay from "react-loading-overlay"

import { Content, Link, config, apiHelper, Modal, toast, fileHelper, Select } from '../';
import { OrdemServicoForm } from './OrdemServicoForm';
import { OrdemServicoConcluir } from './OrdemServicoConcluir';
import { OrdemServicoReabrir } from './OrdemServicoReabrir';
import { InteracaoForm } from './InteracaoForm';
import { RequisicaoMaterial } from './RequisicaoMaterial';
import { AcionamentoForm } from './AcionamentoForm';
import { NovasOsMonitor } from './../dashboard/NovasOsMonitor';
import moment from 'moment-timezone';
import { DashboardRelatoriosClientes } from './DashboardRelatoriosClientes';
import { OrdemServicoDashboard } from './report/OrdemServicoDashboard';
import { OrdemServicoGeral } from './report/OrdemServicoGeral';


let where = '';
export function OrdemServico(props) {
    LoadingOverlay.propTypes = undefined
    const endPoint = 'v3/os/';
    const [data, setData] = useState([]);
    const [permissaoHD, setPermissaoHD] = useState(false);
    const [filter, setFilter] = useState({
        id: '',
        dataCadastro: '',
        nova: true,
        andamento: true,
        pendenciaCliente: true,
        concluida: false,
        finalizada: false,
        resumo: '',
        clienteId: '',
        siteId: '',
        uf: '',
        criador: '',
        tecnico: '',
        nivelSLA: '',
        codigoExternoCliente: '',
        possuiAcionamentoEmAberto: '',
        regionalId: '',
        dataCadastroInicio: '',
        dataCadastroFim: '',
        tipoChamadoId: '',
        grupo: '',
        contatoCliente: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        siteId: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 1,
        endRow: 10
    });

    const fetchData = (where, page = 0, init = 1, end = 10) => {
        setData([]);

        try {
            apiHelper.get(`${endPoint}/pages?filter=${where}&initRow=${init}&endRow=${end}`)
                .then(res => {
                    setData(res.data.data);
                    setParam({
                        ...params,
                        rowId: 0,
                        showModal: false,
                        modalTitle: "",
                        formName: "",
                        siteId: "",
                        totalPages: res.data.totalRegistros,
                        selectedPage: page,
                        initRow: init,
                        endRow: end,
                    });

                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    };

    const onLoad = () => {
        config.validarPermissao("atendimento.listar");
        setPermissaoHD(config.validarClaim('HD'));
        handleSearch();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title, rowId, siteId = '') => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name,
            siteId: siteId
        });
    };

    const handleHide = (refresh = true) => {

        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: "",
            siteId: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            dataCadastro: '',
            nova: true,
            andamento: true,
            pendenciaCliente: true,
            concluida: false,
            finalizada: false,
            resumo: '',
            clienteId: '',
            siteId: '',
            criador: '',
            tecnico: '',
            nivelSLA: '',
            codigoExternoCliente: '',
            possuiAcionamentoEmAberto: '',
            regionalId: '',
            dataCadastroInicio: '',
            dataCadastroFim: '',
            tipoChamadoId: '',
            grupo: '',
            contatoCliente: ''
        });

        fetchData();
    };

    const handleSearch = () => {
        where = '';
        let status2 = [];

        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'resumo' || key === 'criador' || (key === 'tecnico' || key === 'contatoCliente'))
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'nova') {
                status2.push(1);
            }
            else if (key === 'andamento') {
                status2.push(2);
            }
            else if (key === 'pendenciaCliente') {
                status2.push(3);
            }
            else if (key === 'concluida') {
                status2.push(4);
            }
            else if (key === 'finalizada') {
                status2.push(5);
            }
            else if (key === 'dataCadastroInicio') {
                where += `${'dataCadastro'}::greaterEqual::${value2};;AND;;${'dataCadastro'}::lessEqual::${filter.dataCadastroFim} 23:59:59;;AND;;`;
            }
            else if (key !== 'dataCadastroFim')
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        if (status2.length > 0) {
            where += 'statusId::in::' + status2.join(",");
        }

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = (page * params.pageSize) + 1;
        let end = (page * params.pageSize) + params.pageSize;

        fetchData(where, page, init, end);
    };

    const renderPage = () => {
        const pages = Math.ceil(params.totalPages / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + params.totalPages + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const HandleExcel = () => {
        try {
            apiHelper.get(`${endPoint}/pages?filter=${where}&initRow=${1}&endRow=${params.totalPages}`)
                .then(res => {
                    let dataExcel = [];
    
                    res.data.data && res.data.data.map((item) => {
                        let obj = {
                            'OS': item.id,
                            'Data': config.excelDateFormat(item.dataCadastro),
                            'Data Conclusão': (config.dateTimeFix(item.dataConclusao) === '01/01/1900 00:00:00') ? '' : config.excelDateFormat(item.dataConclusao),
                            'Status': item.status,
                            'Nível': item.nivelSLA === 0 ? '' : item.nivelSLA,
                            'SLA': `${item.tempoDecorridoHHMM} de ${item.nivelSLACorrente}h{${Math.round(item.percentualSLACorrente)}%}`,
                            'Estado do SLA': (item.percentualSLACorrente >= 100) ? "Fora do Prazo" : "Dentro do Prazo",
                            'Resumo': item.resumo,
                            'Cliente': item.cliente,
                            'Site': item.site,
                            'Contato Cliente': item.contatoCliente,
                            'UF': item.uf,
                            'Criador': item.criador,
                            'Responsável Despacho': item.despacho,
                            'Último Acionamento': item.tecnico,
                            'Cód. Externo Cliente': item.codigoExternoCliente,
                            'Regional': item.regional,
                            'TipoChamado': item.tipoChamado,
                            'Grupo': item.grupo,
                            'Sub Grupo': item.subGrupo,
                            'Manutenção Executada': item.manutencaoExecutada
                        };
                        dataExcel.push(obj);
                        return null;
                    });
    
                    fileHelper.exportToCSV(dataExcel, 'OS_' + moment().format("DD-MM-YYYY"));
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    };
    

    

    return (
        <Content title="Ordem de Serviço" browserTitle="Ordem de Serviço">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                            {config.validarClaim('atendimento.monitor') && <li><a href="#tab_novasOs" data-toggle="tab">Novas OS</a></li>}
                            {config.validarClaim('atendimento.indicadores') && <li><a href="#tab_report_indicador" data-toggle="tab">Indicadores</a></li>}
                            {config.validarClaim('atendimento.relatorio') && <li><a href="#tab_report_geral" data-toggle="tab">Relatório Geral</a></li>}
                            {config.validarClaim('atendimento.relatorio') && <li><a href="#tab_report_cliente" data-toggle="tab">Relatórios Cliente</a></li>}
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    {config.validarClaim('atendimento.criar') && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("new", "Abrir OS", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova OS</button>}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                            Pesquisar <span className="caret" />
                                        </button>
                                        <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                                <div className="box" style={{ margin: 0 }}>

                                                    <div className="box-body">

                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="row">
                                                                    <div className="form-group col-md-4">
                                                                        <label>OS</label>
                                                                        <input id="id" value={filter.id} type="number" className="form-control input-sm" onChange={handleChange} />
                                                                    </div>

                                                                    <div className="form-group col-md-4">
                                                                        <label>Nível</label>
                                                                        <select id="nivelSLA" className="form-control input-sm" onChange={handleChange} value={filter.nivelSLA}>
                                                                            <option key="" value="">Todos</option>
                                                                            <option value="1">Nível 1</option>
                                                                            <option value="2">Nível 2</option>
                                                                            <option value="3">Nível 3</option>
                                                                            <option value="4">Solicitação</option>
                                                                            <option value="5">Preventiva</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Regional</label>
                                                                        <Select id="regionalId" className="form-control input-sm" onChange={handleChange} value={filter.regionalId}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={`${endPoint}/regional`} valuefield="pkRegional" textfield="nome" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-5">
                                                                        <label>Cliente</label>
                                                                        <Select id="clienteId" className="form-control input-sm" onChange={handleChange} value={filter.clienteId}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={`${endPoint}clientes`} valuefield="id" textfield="nome" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-5">
                                                                        <label>Site</label>
                                                                        <Select id="siteId" className="form-control input-sm" onChange={handleChange} value={filter.siteId} refresh={filter.clienteId}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={filter.clienteId !== '' ? `${endPoint}clientes/${filter.clienteId}/sites` : ''} valuefield="id" textfield="nome" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-2">
                                                                        <label>UF</label>
                                                                        <Select id="uf" className="form-control" onChange={handleChange} value={filter.uf}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint="portal/crud/UF" valuefield="sigla" textfield="sigla" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Resumo</label>
                                                                        <input id="resumo" type="text" className="form-control input-sm" onChange={handleChange} value={filter.resumo} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Criador</label>
                                                                        <input id="criador" type="text" className="form-control input-sm" onChange={handleChange} value={filter.criador} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Último Acionamento</label>
                                                                        <input id="tecnico" type="text" className="form-control input-sm" onChange={handleChange} value={filter.tecnico} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Contato Cliente</label>
                                                                        <input id="contatoCliente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.contatoCliente} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Código Externo</label>
                                                                        <input id="codigoExternoCliente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.codigoExternoCliente} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Possui Ac. Em Aberto</label>
                                                                        <Select id="possuiAcionamentoEmAberto" className="form-control" onChange={handleChange} value={filter.possuiAcionamentoEmAberto}>
                                                                            <option value="">Todos</option>
                                                                            <option value="SIM">Sim</option>
                                                                            <option value="NÃO">Não</option>
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-sm-6">
                                                                        <label>Data Cadastro Início</label>
                                                                        <input id="dataCadastroInicio" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastroInicio} />
                                                                    </div>
                                                                    <div className="form-group col-sm-6">
                                                                        <label>Data Cadastro Fim</label>
                                                                        <input id="dataCadastroFim" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastroFim} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group" style={{ paddingLeft: 20 }}>
                                                                    <label className="checkbox"><input type="checkbox" id="nova" onChange={handleChange} checked={filter.nova ? filter.nova : false} /> nova</label>
                                                                    <label className="checkbox"><input type="checkbox" id="andamento" onChange={handleChange} checked={filter.andamento ? filter.andamento : false} /> em andamento</label>
                                                                    <label className="checkbox"><input type="checkbox" id="pendenciaCliente" onChange={handleChange} checked={filter.pendenciaCliente ? filter.pendenciaCliente : false} /> pendência Cliente</label>
                                                                    <label className="checkbox"><input type="checkbox" id="concluida" onChange={handleChange} checked={filter.concluida ? filter.concluida : false} /> concluída</label>
                                                                    <label className="checkbox"><input type="checkbox" id="finalizada" onChange={handleChange} checked={filter.finalizada ? filter.finalizada : false} /> finalizada</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Tipo Chamado</label>
                                                                <Select id="tipoChamadoId" className="form-control input-sm col-sm-6" onChange={handleChange} value={filter.tipoChamadoId}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint={'portal/crud/tipochamado/'} valuefield="id" textfield="nome" />
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Grupo</label>
                                                                <Select id="grupo" className="form-control" onChange={handleChange} value={filter.grupo}>
                                                                    <option value="">Selecione</option>
                                                                    <option endpoint="portal/crud/grupo/" valuefield="nome" textfield="nome" />
                                                                </Select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="box-footer" style={{ padding: 5 }}>
                                                        <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                        <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                    </div>
                                                </div>
                                        </div>

                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => HandleExcel()} style={{ marginRight: "5px" }}><i className="fa fa-file-excel-o" /> Exportar</button>
                                    </div>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin">
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>#</th>
                                                <th>OS</th>
                                                <th>Data</th>
                                                <th>Status</th>
                                                <th>Nível</th>
                                                <th>SLA</th>
                                                <th>Resumo</th>
                                                <th>Cliente</th>
                                                <th>Site</th>
                                                <th>Contato Cliente</th>
                                                <th>UF</th>
                                                <th>Criador</th>
                                                <th>Último Acionamento</th>
                                                <th>Possui Ac. Em Aberto</th>
                                                <th>Cód. Externo Cliente</th>
                                                <th>Regional</th>
                                                <th>TipoChamado</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .map((item, i) => {
                                                    let color = "";

                                                    if (item.percentualSLACorrente < 40)
                                                        color = 'label-success';
                                                    else if (item.percentualSLACorrente < 70)
                                                        color = 'label-primary';
                                                    else if (item.percentualSLACorrente < 99)
                                                        color = 'label-warning';
                                                    else
                                                        color = 'label-danger';

                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '50px' }}>
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" />
                                                                    </button>
                                                                    <ul className="dropdown-menu" role="menu">
                                                                        <li className="header"><center>{item.id}</center></li>
                                                                        <li className="divider" />

                                                                        {(item.statusId < 4 && config.validarClaim('CriarInteracao')) &&
                                                                            <li><Link replace to="#" onClick={() => handleShowForm("interacao", "Criar Interação", item.id)}><i className="fa fa-comments-o" /> Interações</Link></li>
                                                                        }
                                                                        {(item.statusId < 4 && config.validarClaim("AcionarRegional,AcionarPrestador,AcionarTecnico")) &&
                                                                            <li><Link replace to="#" onClick={() => handleShowForm("acionamento", "Novo Acionamento", item.id, item.siteId)}><i className="fa fa-wrench" /> Acionamento Técnico</Link></li>
                                                                        }
                                                                        {(item.statusId < 4 && config.validarClaim('SolicitarMaterial')) &&
                                                                            <li><Link replace to="#" onClick={() => handleShowForm("material", "Solicitar Material", item.id, item.siteId, item.site)}><i className="fa fa-cart-plus" /> Solicitar Material</Link></li>
                                                                        }
                                                                        {(item.statusId === 4 && config.validarClaim('ReabrirOS')) &&
                                                                            <li><Link replace to="#" onClick={() => handleShowForm("finalizar", "Finalizar OS", item.id)}><i className="fa fa-folder-open-o" /> Finalizar OS</Link></li>
                                                                        }
                                                                        {(item.statusId < 4 && config.validarClaim('ConcluirOS')) &&
                                                                            <li><Link replace to="#" onClick={() => handleShowForm("concluir", "Concluir OS", item.id)}><i className="fa fa-check-square-o" /> Concluir OS</Link></li>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td><Link to={'/atendimento/' + item.id}>{item.id}</Link></td>
                                                            <td>{config.dateTimeFix(item.dataCadastro)}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.nivelSLA === 0 ? '' : item.nivelSLA}</td>
                                                            <td><div className={`label ${color}`}>{`${item.tempoDecorridoHHMM} de ${item.nivelSLACorrente}h`} ({Math.round(item.percentualSLACorrente)}%)</div></td>
                                                            <td>{item.resumo}</td>
                                                            <td>{item.cliente}</td>
                                                            <td>{item.site}</td>
                                                            <td>{item.contatoCliente}</td>
                                                            <td>{item.uf}</td>
                                                            <td>{item.criador}</td>
                                                            <td>{item.tecnico}</td>
                                                            <td>{item.possuiAcionamentoEmAberto}</td>
                                                            <td>{item.codigoExternoCliente}</td>
                                                            <td>{item.regional}</td>
                                                            <td>{item.tipoChamado}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                            {permissaoHD === true &&
                                <div id="tab_novasOs" className="tab-pane">
                                    <NovasOsMonitor timer={1000 * 120} />
                                </div>
                            }
                            <div id="tab_report_indicador" className="tab-pane">
                                <OrdemServicoDashboard />
                            </div>
                            <div id="tab_report_geral" className="tab-pane">
                                <OrdemServicoGeral />
                            </div>
                            {<div id="tab_report_cliente" className="tab-pane">
                                <DashboardRelatoriosClientes />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "new" && <OrdemServicoForm onHide={handleHide} />}
                    {params.formName === "concluir" && <OrdemServicoConcluir parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === "finalizar" && <OrdemServicoReabrir parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === "interacao" && <InteracaoForm parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === "acionamento" && <AcionamentoForm parentId={params.rowId} siteId={params.siteId} onHide={handleHide} />}
                    {params.formName === "material" && <RequisicaoMaterial parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content >
    )
}
