import React, { useState, useEffect } from 'react';
import { Content, config, apiHelper, Modal, toast } from '../';
import { OrcamentoForm } from './OrcamentoForm';
import { OrcamentoInteracao } from './OrcamentoInteracao';
import { OrcamentoResultado } from './OrcamentoResultado';
import { OrcamentoEscopo } from './OrcamentoEscopo';
import { OrcamentoSite } from './OrcamentoSite';
import { OrcamentoFinalizarForm } from './OrcamentoFinalizarForm';
import { OrcamentoImplantacaoForm } from './OrcamentoImplantacaoForm';

export function OrcamentoView(props) {
    const permissaoEscrita = config.validarClaim('orcamento.escrita');
    const rowId = props.match.params.id;

    const [data, setData] = useState({});
    const [params, setParam] = useState({
        showModal: false,
        refresh: false
    });

    const fetchData = () => {
        setData([]);
        config.validarPermissao("orcamento.leitura");
        apiHelper.get(`orcamento/resumo/${rowId}`).then(res => {
            if (config.validarClaim("ConsultorComercial") && res.data.data.responsavelVendaId !== config.idUsuarioLogado()) {
                setData(null);
                window.location.href = '#/erro/';
            }
            else
                setData(res.data.data);
        });
    };

    useEffect(fetchData, [rowId]);

    const handleEdit = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleClose = () => {
        props.history.push({
            pathname: '/v2/orcamento/'
        });
    };

    const handleUpdate = () => {
        fetchData();
        setParam({ ...params, refresh: !params.refresh });
    }

    const handleClone = () => {
        apiHelper.put(`orcamento/${rowId}/duplicar`)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success('Orçamento duplicado e carregado!');
                    props.history.push({
                        pathname: '/v2/orcamento/' + res.data.data
                    });
                }
                else if (res.data.code === 500)
                    toast.warn('Atenção, não foi possível duplicar este orçamento!');
                else
                    toast.error('Atenção, não foi possível salvar o registro.');
            }).catch(error => {
                toast.error('Atenção, não foi possível duplicar este orçamento!');
            });
    }

    return (
        data && <Content title="Detalhes do Orçamento" browserTitle="Detalhes do Orçamento">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id}</h3>
                            <div className="box-tools pull-right">
                                {permissaoEscrita && <React.Fragment>
                                    <button title="Duplicar" type="button" className="btn btn-box-tool" onClick={handleClone}><i className="fa fa-copy" /></button>
                                    {data.status === 'Rascunho' && <button title="Editar" type="button" className="btn btn-box-tool" onClick={handleEdit}><i className="fa fa-pencil" /></button>}
                                </React.Fragment>}
                                <button title="Sair" type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Cliente:</b><br />
                                            {data.nomeCliente}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Consultor:</b><br />
                                            {config.formatName(data.nomeConsultor)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status:</th>
                                        <td>{config.formataLabelStatus(data.status)}</td>
                                    </tr>
                                    <tr>
                                        <th>Criador:</th>
                                        <td>{data.nomeCriador}</td>
                                    </tr>
                                    <tr>
                                        <th>Data:</th>
                                        <td>{config.dateFix(data.dataCadastro)}</td>
                                    </tr>
                                    <tr>
                                        <th>Faturamento:</th>
                                        <td>{data.estadodeFaturamentoEmpresa}</td>
                                    </tr>
                                    <tr>
                                        <th>Destino:</th>
                                        <td>{data.estadodeFaturamentoCliente}</td>
                                    </tr>
                                    <tr>
                                        <th>Tipo Orçamento:</th>
                                        <td>{data.tipoOrcamento}</td>
                                    </tr>
                                    <tr>
                                        <th>Descrição:</th>
                                        <td>{data.descricao}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_resultado" data-toggle="tab">Resultado</a></li>
                            {data.status === 'Rascunho' && permissaoEscrita && <li><a href="#tab_site" data-toggle="tab">Sites</a></li>}
                            <li><a href="#tab_escopo" data-toggle="tab">Escopo</a></li>
                            {permissaoEscrita && <li><a href="#tab_implantacao" data-toggle="tab">Implantação</a></li>}
                            <li><a href="#tab_interacao" data-toggle="tab">Interações</a></li>
                            {(data.status !== 'Rascunho' && data.propostaGerada) && permissaoEscrita && <li><a href="#tab_finalizarOrcamento" data-toggle="tab">Finalizar Orçamento</a></li>}
                        </ul>
                        <div className="tab-content">
                            <div id="tab_resultado" className="tab-pane active">
                                <OrcamentoResultado parentId={rowId} status={data.status} propostaGerada={data.propostaGerada} anexoProposta={data.anexoProposta} onUpdate={handleUpdate} refresh={params.refresh} />
                            </div>
                            <div id="tab_site" className="tab-pane">
                                <OrcamentoSite parentId={rowId} clienteId={data.clienteId} onUpdate={handleUpdate} refresh={params.refresh} />
                            </div>
                            <div id="tab_escopo" className="tab-pane">
                                <OrcamentoEscopo parentId={rowId} status={data.status} clienteId={data.clienteId} enviadoRevisao={data.enviadoRevisao} onUpdate={handleUpdate} refresh={params.refresh} />
                            </div>
                            <div id="tab_implantacao" className="tab-pane">
                                <OrcamentoImplantacaoForm parentId={rowId} />
                            </div>
                            <div id="tab_interacao" className="tab-pane">
                                <OrcamentoInteracao parentId={rowId} refresh={params.refresh} status={data.status}/>
                            </div>
                            <div id="tab_finalizarOrcamento" className="tab-pane">
                                <OrcamentoFinalizarForm parentId={rowId} fetchData={fetchData} status={data.status} onUpdate={handleUpdate} refresh={params.refresh} params={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Orçamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrcamentoForm rowId={rowId} nomeCliente={''} onHide={handleHide} />
                </Modal.Body>
            </Modal>
        </Content>
    )
}
