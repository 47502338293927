import React from 'react';
import { toast } from 'react-toastify';
import * as _ from "lodash";
import { OrdemServico } from '../modules/atendimentoV2/OrdemServico';
import { Fornecedor } from '../modules';
import { RequisicaoMaterial } from '../modules/atendimentoV2/RequisicaoMaterial';
import { GraficoIndicadores } from '../modules/atendimentoV2/report/GraficoIndicadores';
import { TrackerGoogleMaps } from '../modules/atendimentoV2/report/TrackerGoogleMaps';
import { VeolinkApresentationCard } from '../modules/dashboard/VeolinkApresentationCard';
import moment from 'moment-timezone';

export const config = {
    apiUrl,
    tenant,
    currencyFix,
    dateFix,
    dateTimeFix,
    dateTimeDateAndHours,
    dateTimeHours,
    validarPermissao,
    validarClaim,
    idUsuarioLogado,
    httpResult,
    ordenarColunas,
    checkFiltroPreenchido,
    getFolderModuloCliente,
    getTotalSemanas,
    getFolderModuloOportunidade,
    formatName,
    getFolderModuloProjeto,
    getFolderModuloRHFotos,
    getFolderModuloRHCurriculos,
    timeConvert,
    validarClaimExiste,
    excelDateFormat,
    formataLabelStatus,
    formataLabelStatusCliente,
    selectCard,
    capitalize,
    getRandomColor,
    removeSpacesAndToUpper,
    removeSpacesAndToUpperFirstLetter,
    getClaims,
    formatField,
};

const Components = {
    OrdemServico: OrdemServico,
    GraficoIndicadores: GraficoIndicadores,
    TrackerGoogleMaps: TrackerGoogleMaps,
    Fornecedor: Fornecedor,
    RequisicaoMaterial: RequisicaoMaterial,
    VeolinkApresentationCard: VeolinkApresentationCard
};

var asc = true;

function apiUrl() {
    return localStorage.getItem('apiUrl');
}

function tenant() {
    return JSON.parse(localStorage.getItem('tenant'));
}

function currencyFix(val) {
    if (val)
        return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    return 0;
}

function timeConvert(val) {
    if (val) {
        let num = val;
        let hours = (num / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        return rhours.toString().padStart(2, '0') + ":" + rminutes.toString().padStart(2, '0');
    }

    return 0;
}

function dateFix(val, culture) {
    if (val) {
        if (!culture)
            culture = 'pt-BR';

        return new Date(val).toLocaleDateString(culture);
    }

    return val;
}

function dateTimeFix(val, culture) {
    if (val) {
        if (!culture)
            culture = 'pt-BR';

        return new Date(val).toLocaleString(culture).replace(',','');
    }

    return "";
}

// Função para converter a data no formato correto para o Excel
function excelDateFormat(dateString) {
    if (!dateString) return "";

    let date = new Date(dateString);
    return date || "";
}

function dateTimeDateAndHours(val, culture) {
    if (val) {
        if (!culture)
            culture = 'pt-BR';

        var data = dateTimeFix(val).split(' ')
        var hora = data[1].split(':');
        return data[0] + " " + hora[0] + ":" + hora[1];
    }

    return "";
}

function formatField(val) {
    var formats = [
        "MM/DD/YYYY HH:mm:ss",
        "YYYY-MM-DD HH:mm:ss"
    ];

    if (val) {
        if (moment(val, formats, true).isValid()) {
            if (!val.includes('1900'))
                return dateTimeFix(val);
            else return '-';
        }
    }

    return val;
}

function dateTimeHours(val, culture) {
    if (val) {
        if (!culture)
            culture = 'pt-BR';

        var data = dateTimeFix(val).split(' ')[1].split(':');
        return data[0] + ":" + data[1];
    }

    return "";
}

function getClaims() {
    var claims = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : [];
    return claims;
}

function validarPermissao(permissao) {
    var claims = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : '';
    if (!claims.includes(permissao)) {
        window.location.href = '#/erro/';
    }
}

function validarClaim(permissao) {
    let claims = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : '';
    let res = permissao.split(",");

    for (var i = 0; i < res.length; i++) {
        if (claims.includes(res[i]))
            return true;
    }

    return claims.includes(permissao);
}

function validarClaimExiste(permissao) {
    let claims = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : '';
    let res = permissao.split(",");

    for (var i = 0; i < res.length; i++) {
        if (claims.includes(res[i]))
            return true;
    }

    return false;
}

function idUsuarioLogado() {
    return parseInt(localStorage.getItem('id'));
}

function httpResult(res) {
    if (res.code === 200 || res.status === 200)
        toast.success('Dados salvos com sucesso');
    else if (res.code === 400)
        toast.warn(res.data.data);
    else
        toast.error('Atenção, não foi possível salvar o registro.');
}

function ordenarColunas(data, nomeVariavelColuna) {
    let sortedObjs = '';

    if (data === null || data === undefined || data.length === 0)
        return data;

    if (Object.prototype.toString.call(data[0][nomeVariavelColuna]) === '[object Date]')
        sortedObjs = _.orderBy(data, c => new Date(config.dateFix(nomeVariavelColuna, 'en-US')), asc === true ? 'asc' : 'desc');
    else
        sortedObjs = _.orderBy(data, nomeVariavelColuna, asc === true ? 'asc' : 'desc');

    asc = asc === true ? false : true;
    return sortedObjs;
}

function checkFiltroPreenchido(obj) {
    for (var key in obj) {
        if (obj[key] !== '' && obj[key] !== null)
            return true
    }
    return false;
}

function getFolderModuloCliente() {
    return 'Cliente';
}

function getFolderModuloOportunidade() {
    return 'Proposta';
}

function getFolderModuloProjeto() {
    return 'Projeto';
}

function getFolderModuloRHFotos() {
    return 'FuncionarioFotos';
}

function getFolderModuloRHCurriculos() {
    return 'FuncionarioCurriculos';
}

function getTotalSemanas(date) {
    let anoAtual = date.split('-')[0];
    let primeiraDataAnoAtual = new Date(parseInt(anoAtual), 0, 1);
    let dateInformada = new Date(date.split('-')[0], parseInt(date.split('-')[1]) - 1, date.split('-')[2]);

    var diff = (dateInformada.getTime() - primeiraDataAnoAtual.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
}

function formatName(value) {
    if (!value) return;
    let names = value.split(" ");
    return _.capitalize(names[0]) + " " + _.capitalize(names[names.length - 1]);
}

function capitalize(value) {
    if (!value) return;
    const words = value.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
}

function formataLabelStatus(status) {
    switch (status) {
        case "Rascunho":
            return <span className="label label-primary">{status}</span>;
        case "Fechado":
            return <span className="label label-success">{status}</span>;
        case "AguardandoAprovacaoFinanceira":
            return <span className="label label-warning">Aguardando Aprovacao</span>;
        case "AguardandoAprovacaoCliente":
            return <span className="label label-warning">Aguardando Cliente</span>;
        default:
            return <span className="label label-default">{status}</span>;
    }
};

function getRandomColor(size) {
    let colors = []

    for (let index = 0; index < (size || 1); index++) {
        colors.push('#' + (Math.random().toString(16) + '0000000').slice(2, 8));
    }

    return colors;
}

function removeSpacesAndToUpper(text) {
    return text.replace(/\s\s+/g, ' ').toUpperCase().trim();
}

function removeSpacesAndToUpperFirstLetter(text) {
    return text.replace(/\s\s+/g, ' ').trim().toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

function formataLabelStatusCliente(status) {
    switch (status) {
        case "Rascunho":
            return <span className="label label-primary">Novo</span>;
        case "Fechado":
        case "AguardandoAprovacaoFinanceira":
            return <span className="label label-success">Em Processamento</span>;
        case "AguardandoAprovacaoCliente":
            return <span className="label label-warning">Aguardando Cliente</span>;
        default:
            return <span className="label label-default">{status}</span>;
    }
};

function selectCard(nome) {
    return React.createElement(Components[nome]);
};
